import React from 'react';
import { FaqItem } from '../common';

const Faq = () => {
  const [activeAnswerIndex, setActiveAnswerIndex] = React.useState(1);

  const onClick = index => {
    if (index === activeAnswerIndex) {
      setActiveAnswerIndex(null);
    } else {
      setActiveAnswerIndex(index);
    }
  };

  return (
    <section className="bg-green-100" id="faq">
      <div className="container px-4 py-8 lg:py-24 flex flex-col justify-center items-center">
        <div className="max-w-[800px] w-full">
          <h1 className="text-secondary-main pb-8 text-center">GY.I.K.</h1>

          <FaqItem
            question="Nem vagyok várandós és nincsen még gyermekem akkor is regisztrálhatok?"
            index={1}
            handleClick={() => onClick(1)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 text-lg mt-4 text-justify">
              Természetesen, bármilyen érdeklődőt örömmel látunk a platformon, a platformot nyugodtan megoszthatja más családtagokkal, ismerősökkel is így nagymama, nagypapa és a szélesebb család is új ismeretekhez juthat.
            </p>
          </FaqItem>
          <FaqItem
            question="Mobilról is használhatom a felületet?"
            index={2}
            handleClick={() => onClick(2)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 text-lg mt-4 text-justify">
              A képzést mindenki a saját ütemében végezheti el online, bármikor,
              bárhonnan.
            </p>
          </FaqItem>
          <FaqItem
            question="A tananyagokból kell vizsgát tenni?"
            index={3}
            handleClick={() => onClick(3)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 text-lg mt-4 text-justify pb-3">
              A tananyagok egyes részei után van lehetőség tesztet kitölteni, aminek eredménye csupán visszaigazolás Önnek, hogy jól sajátította-e el az anyagokat. A teszt eredménye nem jár következménnyel, de az azt sikeresen kitöltők partneri felajánlások sorsolásában részt vehetnek majd és így különböző nyereményekhez juthatnak.
            </p>
          </FaqItem>
          <FaqItem
            question="Ha elvégeztem az összes tananyagot, akkor számomra véget ér a program?"
            index={4}
            handleClick={() => onClick(4)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 text-lg mt-4 text-justify">
             Nem, az Evita folyamatosan bővülni fog, emellett pedig partnereink eseményei online vagy az Ön lakhelyéhez közel is megvalósulhatnak, amiken ingyenes részt vehet, valamint probléma esetén partnereinkhez bármikor fordulhat.
            </p>
          </FaqItem>
          <FaqItem
            question="Szervezetemmel/cégemmel partner vagy támogató szeretnék lenni, hogyan tehetem ezt meg?"
            index={5}
            handleClick={() => onClick(5)}
            activeAnswerIndex={activeAnswerIndex}
          >
            <p className="text-gray-700 text-lg mt-4 text-justify">
              Amennyiben szakmai partnerként csatlakozna az Evita programjához írjon a titkarsag@mik.ma e-mail címre “Evita partner - csatlakozási szándék” címmel. Amennyiben céges vagy magánadományozóként segíteni munkánkat kattintson ide és tekintse meg az adományozási szekciót.
            </p>
          </FaqItem>
        </div>
      </div>
    </section>
  );
};

export default Faq;
