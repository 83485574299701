import React from 'react';
import PropTypes from 'prop-types';
import { scroller, Element } from 'react-scroll';
import { Layout } from '../components/common';
import {
  Hero,
  Introduction,
  WhatsMore,
  Testimonial,
  Register,
  PartnerOffers,
  Donate,
} from '../components/home';
import { Faq } from '../components/shared';

const IndexPage = ({ location }) => {
  const { hash } = location;

  React.useLayoutEffect(() => {
    if (hash) {
      setTimeout(() => {
        const target = hash.split('#')[1];
        scroller.scrollTo(target, {
          duration: 700,
          delay: 0,
          smooth: 'easeInOutQuart',
          offset: -100
        });
      }, 1);
    }
  }, [hash]);

  return (
    <Layout
      seo={{
        title: 'Evita családtámogató platform',
        lang: 'hu',
        description:
          'Hitelesített, gyakorlati segédletek várandósok, kismamák, apukák számára a családtervezéstől egészen az iskolaérettségig.'
      }}
      baseRoute="/"
      location={location}
    >
      <Element name="intro">
        <Hero />
      </Element>
      <Element name="steps">
        <Introduction />
      </Element>
      <Element name="why">
        <WhatsMore />
      </Element>
      <Element name="partners">
        <PartnerOffers />
      </Element>
      <Element name="testimonials">
        <Testimonial />
      </Element>
      <Element name="faq">
        <Faq />
      </Element>
      <Element name="donate">
        <Donate />
      </Element>
      <Element name="register">
        <Register />
      </Element>
    </Layout>
  );
};

IndexPage.propTypes = {
  location: PropTypes.shape().isRequired
};

export default IndexPage;
